import './DriversCanvas.scss';

import { t } from 'i18next';
import { chunk, isEqual } from 'lodash-es';

import {
  clearMarkers,
  clearPopup,
  getDriverMarkerIconUrl,
  getMarkerIcon,
  getUpdatedWorkerData,
  getVisibleDrivers,
  isLocationUpdatedInLast5Days,
  shouldReUpdateCurrentDriverMarker,
  shouldUpdateDriverMarker,
} from '@yojee/ui/map/components/helpers';

import copyIcon from './assets/file-copy.svg';
import { DISABLED_LEGEND_CONDITION, LEGEND_DRIVER_KEY } from './MapLegend/constants';
import { createPopupClass } from './popup-canvas';

// MARKERS STORAGE
let driversMarkersMapping = {};
const driversPopupsMapping = {};
const driversFramesMapping = {};

// Filter
let WORKER_FILTER = [];
let DISABLED_LEGEND = [];

class MapController {
  /* SET GOOGLE INSTANCE AND START LISTENNING TO DRIVER MOVEMENT EVENTS */

  // Store current workers on a map structure for easy for
  // update driver marker (change color, icon, hidden or not, etc)
  static workerIdDataMap = {};
  static disableAutoUpdateNewDriverMarkers = false;
  static clickablePopupInfoDriver = false;

  static async setGoogleInstance(google, onDriverMarkerClick, disableMarkerClick = false) {
    this.map = google.map;
    this.maps = google.maps;
    this.onDriverMarkerClick = onDriverMarkerClick;
    this.disableMarkerClick = disableMarkerClick;
    this.isDrawing = false;
    this.drawQueue = [];
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
    this.AdvancedMarkerElement = AdvancedMarkerElement;

    MapController.disableAutoUpdateNewDriverMarkers = false;
    MapController.clickablePopupInfoDriver = false;
    MapController.monitorDriverMovement();
  }

  static driverMove(data) {
    if (!data || !data.id || !data.location) return;
    const driverID = data.id;
    const newLoc = data.location;
    const marker = driversMarkersMapping[driverID];
    if (!marker) {
      //TODO: DRAW A NEW DRIVER
      return;
    }
    if (!driversFramesMapping[driverID]) {
      driversFramesMapping[driverID] = [];
    }
    const frames = driversFramesMapping[driverID];
    frames.push(newLoc);
  }

  static setWorkerFilter(filter) {
    if (!isEqual(WORKER_FILTER, filter)) {
      WORKER_FILTER = filter;
    }
  }

  static setDisabledLegend(legend) {
    DISABLED_LEGEND = legend;
  }

  static showOptimsiedDrivers(driverIDs) {
    const { map } = this;
    if (driverIDs.length > 0) {
      Object.keys(driversMarkersMapping).forEach((driverID) => {
        if (!driverIDs.includes(driverID)) {
          driversMarkersMapping[driverID].setMap(null);
        }
      });
    } else {
      Object.values(driversMarkersMapping).forEach((marker) => {
        marker.setMap(map);
      });
    }
  }
  /* END OF DERIVER MOVEMENT */

  /* START OF MARKER DRAWING */
  static drawWorkerMarkers(inputWorkers, showStartLocation = false) {
    const workers = (inputWorkers || []).slice(0, 500);
    if (this.isDrawing) {
      this.drawQueue.push(() => this.drawWorkerMarkers(workers, showStartLocation));
      return;
    }

    this.isDrawing = true;

    this.workerIdDataMap = workers.reduce((acc, worker) => {
      acc[worker.id] = worker;
      return acc;
    }, {});

    const { map, maps, onDriverMarkerClick, disableMarkerClick } = this;
    /* Clear driver markers */
    Object.values(driversMarkersMapping).forEach((marker) => {
      marker.setMap(null);
    });
    driversMarkersMapping = {};

    const visibleWorkers = getVisibleDrivers(workers, WORKER_FILTER);

    const chunkedData = chunk(visibleWorkers, 100);
    const drawMarkers = () => {
      const data = chunkedData.shift() || [];
      MapController.addMarkers(data, maps, map, onDriverMarkerClick, disableMarkerClick, showStartLocation);
      if (chunkedData.length > 0) {
        setTimeout(() => {
          drawMarkers();
        }, 200);
      } else {
        this.isDrawing = false;
        this.processQueue();
      }
    };
    drawMarkers();
  }

  static processQueue() {
    if (this.drawQueue.length > 0) {
      const nextDraw = this.drawQueue.shift();
      nextDraw();
    }
  }

  static focusOnWorker(worker) {
    const { map } = this;
    const marker = driversMarkersMapping[worker.id];
    if (!marker || !marker.getPosition) return;
    const workerLoc = marker.getPosition();
    map.setCenter({ lat: workerLoc.lat(), lng: workerLoc.lng() });
    map.setZoom(30);
  }

  static formatHours(hours) {
    return hours < 10 ? `0${hours}` : hours;
  }
  static formatMinutes(minutes) {
    return minutes < 10 ? `0${minutes}` : minutes;
  }

  static addMarkers = (
    workerData,
    maps,
    map,
    onDriverMarkerClick,
    disableMarkerClick = false,
    showStartLocation = false
  ) => {
    // Ignore rendering driver markers that do not exist in WORKER_FILTER.
    if (WORKER_FILTER.length > 0 && workerData.length > 0) {
      workerData = workerData.filter((worker) => WORKER_FILTER.includes(worker.id));
    }

    if (DISABLED_LEGEND.some((legendKey) => LEGEND_DRIVER_KEY.includes(legendKey)) > 0 && workerData.length > 0) {
      workerData = workerData.filter(
        (worker) => !DISABLED_LEGEND.some((legendKey) => DISABLED_LEGEND_CONDITION[legendKey]?.(worker))
      );
    }

    const startZIndex = 12000;
    const Popup = createPopupClass(maps);

    workerData.forEach((worker, zIndex) => {
      if (!worker) return;

      const iconUrl = getDriverMarkerIconUrl(worker);
      const icon = getMarkerIcon(iconUrl, new maps.Size(22, 22));
      const bigIcon = getMarkerIcon(iconUrl, new maps.Size(24, 24));

      const MARKER_CLASSNAME = 'advanced-marker-content';
      const INFO_WINDOW_CLASSNAME = 'advanced-marker-content__infoWindow';

      const markerContent = document.createElement('div');
      markerContent.classList.add(MARKER_CLASSNAME);

      markerContent.innerHTML = `
        <div style="width: 24px; height: 24px" class="'advanced-marker-content__icon">
        <img 
          src="${iconUrl}" 
          style="width: 100%; height: 100%; object-fit: cover;"
        />
        </div>
        <div class="${INFO_WINDOW_CLASSNAME}"></div>
      `;

      if (worker.location && worker.location.lat && worker.location.lng) {
        if (MapController.clickablePopupInfoDriver) {
          const content = document.createElement('div');
          content.className = 'clickable-info-window';

          const infoContent = document.createElement('div');
          infoContent.className = 'info-content';

          const nameElement = document.createElement('div');
          nameElement.className = 'name-container';

          const nameContent = document.createElement('div');
          nameContent.className = 'name-content';

          let nameText = worker.name;
          if (worker.isPartnerWorker && !worker.company_name) {
            nameText = nameText.replace(' driver', '');
          }

          nameContent.innerText = nameText;
          nameElement.appendChild(nameContent);

          if (!worker.isPartnerWorker || (worker.isPartnerWorker && worker.company_name)) {
            const copyButton = document.createElement('button');
            copyButton.className = 'copy-button';
            const copyButtonIcon = document.createElement('img');
            copyButtonIcon.src = copyIcon;
            copyButtonIcon.className = 'icon-copy';
            copyButton.appendChild(copyButtonIcon);
            copyButton.onmousedown = () => {
              navigator.clipboard.writeText(nameText);
            };

            nameElement.appendChild(copyButton);
          }

          infoContent.appendChild(nameElement);

          if (worker.assigned_vehicle?.plate_number) {
            const vehicleElement = document.createElement('div');
            vehicleElement.className = 'vehicle-container';

            const vehicleContent = document.createElement('div');
            vehicleContent.className = 'vehicle-content';
            vehicleContent.innerText = worker.assigned_vehicle?.plate_number;

            const copyVehicleButton = document.createElement('button');
            copyVehicleButton.className = 'copy-button';
            const copyVehicleButtonIcon = document.createElement('img');
            copyVehicleButtonIcon.src = copyIcon;
            copyVehicleButtonIcon.className = 'icon-copy';
            copyVehicleButton.appendChild(copyVehicleButtonIcon);
            copyVehicleButton.onmousedown = () => {
              navigator.clipboard.writeText(worker.assigned_vehicle?.plate_number);
            };

            vehicleElement.appendChild(vehicleContent);
            vehicleElement.appendChild(copyVehicleButton);

            infoContent.appendChild(vehicleElement);
          }

          if (worker.company_name) {
            const partnerContent = document.createElement('div');
            partnerContent.className = 'partner-content';
            partnerContent.innerText = worker.company_name;

            infoContent.appendChild(partnerContent);
          }

          content.appendChild(infoContent);

          const locationContent = document.createElement('div');
          locationContent.className = 'location-content';
          const locationText = document.createElement('p');
          locationText.innerText = t('Location update');

          const locationTime = document.createElement('p');
          const updatedTime = new Date(worker.location_updated_at);

          const formattedHours = this.formatHours(updatedTime.getHours());
          const formattedMinus = this.formatMinutes(updatedTime.getMinutes());

          const formattedTime = `${updatedTime.getDate()} ${updatedTime.toLocaleString('default', {
            month: 'long',
          })} ${formattedHours}:${formattedMinus}`;
          locationTime.innerText = formattedTime;

          locationContent.appendChild(locationText);
          locationContent.appendChild(locationTime);

          content.appendChild(locationContent);

          const actionContent = document.createElement('div');
          actionContent.className = 'action-content';
          const actionExplore = document.createElement('div');
          actionExplore.className = 'action-btn';
          actionExplore.innerText = t('Explore');
          actionExplore.onmousedown = () => {
            const exploreUrl = worker.isPartnerWorker
              ? `/explore?partnerDriverId=${worker.id}&mainFilter=ALL&viewBy=TASKS`
              : `/explore?driverIds[0]=${worker.id}&mainFilter=ALL&viewBy=TASKS`;
            window.open(exploreUrl, '_blank');
          };
          actionContent.appendChild(actionExplore);

          content.appendChild(actionContent);

          const infoWindow = new Popup(new maps.LatLng(worker.location.lat, worker.location.lng), content);

          const workerMarker = new this.AdvancedMarkerElement({
            position: worker.location,
            map,
            content: markerContent,
            zIndex: startZIndex + zIndex,
          });

          workerMarker.content.getRootNode().addEventListener('click', function () {
            const popupEl = infoWindow.getMap();
            workerMarker.setIcon(bigIcon);
            // close popup when it's already opened
            if (popupEl) {
              infoWindow.setMap(null);
              return;
            }
            infoWindow.setMap(map);
            // close other popups
            Object.keys(driversPopupsMapping).forEach((key) => {
              if (key !== worker.id.toString()) {
                driversPopupsMapping[key].setMap(null);
              }
            });
          });

          maps.event.addListener(map, 'click', function () {
            // workerMarker.setIcon(icon);
            infoWindow.setMap(null);
          });

          driversMarkersMapping[worker.id] = workerMarker;
          driversPopupsMapping[worker.id] = infoWindow;
        } else {
          const content = document.createElement('div');
          const indicator = document.createElement('div');
          indicator.className = 'online-indicator';
          indicator.style.backgroundColor = worker.status === 'on_duty' ? '#8ec94d' : 'lightgray';
          content.appendChild(indicator);
          const namediv = document.createElement('div');
          namediv.innerText = worker.name;
          content.appendChild(namediv);

          const infoWindow = new Popup(new maps.LatLng(worker.location.lat, worker.location.lng), content);

          const workerMarker = new this.AdvancedMarkerElement({
            map,
            position: worker.location,
            content: markerContent,
            zIndex: startZIndex + zIndex,
          });

          workerMarker.content.getRootNode().addEventListener('mouseenter', function () {
            infoWindow.setMap(map);
          });

          workerMarker.content.getRootNode().addEventListener('mouseout', function () {
            infoWindow.setMap(null);
          });

          !disableMarkerClick &&
            workerMarker.content.getRootNode().addEventListener('click', function () {
              onDriverMarkerClick(worker);
            });
          driversMarkersMapping[worker.id] = workerMarker;
          driversPopupsMapping[worker.id] = infoWindow;
        }
      }

      if (showStartLocation && worker.start_location) {
        const contentStartLocation = document.createElement('div');
        const indicator = document.createElement('div');
        indicator.className = 'online-indicator';
        indicator.style.backgroundColor = worker.status === 'on_duty' ? '#8ec94d' : 'lightgray';
        contentStartLocation.appendChild(indicator);
        const namediv = document.createElement('div');
        namediv.innerText = worker.name + ' start location';
        contentStartLocation.appendChild(namediv);

        const infoStartLocationWindow = new Popup(
          new maps.LatLng(worker.start_location.lat, worker.start_location.lng),
          contentStartLocation
        );
        const workerStartLocationMarker = new this.AdvancedMarkerElement({
          position: worker.start_location,
          map,
          content: markerContent,
          zIndex: startZIndex + zIndex,
          opacity: 0.5,
        });

        workerStartLocationMarker.content.addEventListener('mouseover', function () {
          // workerStartLocationMarker.setIcon(bigIcon);
          infoStartLocationWindow.setMap(map);
        });
        workerStartLocationMarker.content.addEventListener('mouseout', function () {
          // workerStartLocationMarker.setIcon(icon);
          infoStartLocationWindow.setMap(null);
        });
        driversMarkersMapping[worker.id + '_start_location'] = workerStartLocationMarker;
        driversPopupsMapping[worker.id + '_start_location'] = infoStartLocationWindow;
      }
    });
  };

  static updateWorkersOnGoingTasksCount({
    worker_id: workerId,
    ongoing_tasks_count: currentWorkerGoingTaskCount,
    worker_id_and_ongoing_tasks_count_map: workerIdAndOngoingTasksCountMap,
  }) {
    const { map, maps, onDriverMarkerClick, disableMarkerClick } = this;
    if (!map || !maps) return;

    if (workerId) {
      const updatedWorkerData = getUpdatedWorkerData(this.workerIdDataMap, workerId, currentWorkerGoingTaskCount);
      this.workerIdDataMap[workerId] = updatedWorkerData;

      clearMarkers(driversMarkersMapping, workerId);
      MapController.addMarkers([updatedWorkerData], maps, map, onDriverMarkerClick, disableMarkerClick);
    } else {
      const updatedWorkersData = [];

      for (const [workerId, onGoingTaskCount] of Object.entries(workerIdAndOngoingTasksCountMap)) {
        const updatedWorkerData = getUpdatedWorkerData(this.workerIdDataMap, workerId, onGoingTaskCount);
        this.workerIdDataMap[workerId] = updatedWorkerData;

        clearMarkers(driversMarkersMapping, workerId);
        updatedWorkersData.push(updatedWorkerData);
      }

      MapController.addMarkers(updatedWorkersData, maps, map, onDriverMarkerClick, disableMarkerClick);
    }
  }

  static updateWorkersData(newWorkerData) {
    const { map, maps, onDriverMarkerClick, disableMarkerClick } = this;
    if (!map || !maps) return;

    const workerId = newWorkerData.id;

    if (workerId) {
      const currentDriverData = this.workerIdDataMap[workerId];
      const updatedWorkerData = { ...this.workerIdDataMap[workerId], ...newWorkerData };
      if (!MapController.disableAutoUpdateNewDriverMarkers || !!currentDriverData) {
        this.workerIdDataMap[workerId] = updatedWorkerData;
      }

      if (!isLocationUpdatedInLast5Days(updatedWorkerData)) {
        clearMarkers(driversMarkersMapping, updatedWorkerData.id);
        clearPopup(driversPopupsMapping, updatedWorkerData.id);
      } else if (
        shouldUpdateDriverMarker(currentDriverData, updatedWorkerData, MapController.disableAutoUpdateNewDriverMarkers)
      ) {
        clearMarkers(driversMarkersMapping, workerId);
        clearPopup(driversPopupsMapping, workerId);
        MapController.addMarkers([updatedWorkerData], maps, map, onDriverMarkerClick, disableMarkerClick);
      }
    }
  }

  static monitorDriverMovement = () => {
    let waitDuration = 5000;
    const driverIDs = Object.keys(driversFramesMapping);
    if (driverIDs.length > 0) {
      waitDuration = 20;
      driverIDs.forEach((drID) => {
        const frames = driversFramesMapping[drID];
        if (frames.length >= 1) {
          const nextLoc = frames.shift();
          const marker = driversMarkersMapping[drID];
          if (marker && marker.setPosition) {
            marker.setPosition(nextLoc);
          }
        } else {
          delete driversFramesMapping[drID];
        }
      });
    }
    setTimeout(() => {
      MapController.monitorDriverMovement();
    }, waitDuration);
  };

  static autoUpdateDriverMarkers = () => {
    const { map, maps, onDriverMarkerClick, disableMarkerClick } = this;
    if (!map || !maps) return;

    return setInterval(() => {
      if (MapController.disableAutoUpdateNewDriverMarkers) return;

      const workersNeedUpdate = [];

      Object.values(this.workerIdDataMap).forEach((worker) => {
        const currentWorkerMarker = driversMarkersMapping[worker.id];

        if (!isLocationUpdatedInLast5Days(worker)) {
          clearMarkers(driversMarkersMapping, worker.id);
        } else if (shouldReUpdateCurrentDriverMarker(currentWorkerMarker, worker)) {
          workersNeedUpdate.push(worker);
          clearMarkers(driversMarkersMapping, worker.id);
        }
      });

      MapController.addMarkers(workersNeedUpdate, maps, map, onDriverMarkerClick, disableMarkerClick);
    }, 60 * 1000);
  };
}

export default MapController;
